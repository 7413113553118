<template>
    <transition name="fade" :duration="{ enter: 300, leave: 300 }">
        <div v-if="showDiv && emptyStateBanner == 1" class="empty" @click="openUrlAction('/banners')">
          <div class="empty-banner">
            <div class="empty-banner__container">
              <div class="empty-banner__close">
                <svg  @click.stop="closeBanner"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M7.05591 18.0035C6.78752 18.0035 6.51913 17.9046 6.30724 17.6928C5.89759 17.2831 5.89759 16.6051 6.30724 16.1954L16.1954 6.30724C16.6051 5.89759 17.2831 5.89759 17.6928 6.30724C18.1024 6.71689 18.1024 7.39494 17.6928 7.80459L7.80459 17.6928C7.5927 17.9046 7.32431 18.0035 7.05591 18.0035Z" fill="#878787"/>
                  <path d="M16.9441 18.0035C16.6757 18.0035 16.4073 17.9046 16.1954 17.6928L6.30724 7.80459C5.89759 7.39494 5.89759 6.71689 6.30724 6.30724C6.71689 5.89759 7.39494 5.89759 7.80459 6.30724L17.6928 16.1954C18.1024 16.6051 18.1024 17.2831 17.6928 17.6928C17.4809 17.9046 17.2125 18.0035 16.9441 18.0035Z" fill="#878787"/>
                </svg>
              </div>
              <p class="add-banner-icon"><IconSax name="gallery-add" size="32px" /></p>
              <p class="add-banner">{{ $t('empty_state.carousel_title') }}</p>
              <p class="add-banner-text">{{ $t('empty_state.carousel_recommended_size') }}</p>
              <p class="add-banner-text">{{ $t('empty_state.carousel_desktop_size') }}</p>
              <p class="add-banner-text">{{ $t('empty_state.carousel_mobile_size') }}</p>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    computed: {
      ...mapGetters({
        getEmptyState: "home/getEmptyState"
      })
    },
    mounted() {
      if(this.getEmptyState){
        this.emptyStateBanner = this.getEmptyState.empty_state_banner;
      }
    },
    data() {
        return {
            showDiv: true,
            emptyStateBanner: false
        };
    },
    methods: {
        ...mapActions({
          disableEmptyState: "home/disableEmptyState",
        }),
        openUrlAction(url) {
            this.$router.push(url);
        },
        closeBanner(){
            this.showDiv = !this.showDiv;
            this.disableEmptyState("empty_state_banner");
        }
    }
}

</script>

<style lang="scss" scoped>

.empty {
  position: relative;
  cursor: pointer;

&-banner {
    height: 260px;
    background-color: var(--box-background-color);

    @media screen and (min-width: 390px) {
      height: 216px;
    }

    @media screen and (min-width: 414px) {
      height: 230px;
    }

    @media screen and (min-width: 768px) {
      height: 165px;
    }

    @media screen and (min-width: 960px) {
      height: 210px;
    }

    @media screen and (min-width: 1080px) {
      height: 242px;
    }

    @media screen and (min-width: 1280px) {
      height: 292px;
    }

    @media screen and (min-width: 1336px) {
      height: 300px;
    }

    &__close{
      position: absolute;
      top: 0;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      padding: 1em;
      cursor: pointer;
    }
    &__container{
      height: 100%;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom: var(--empty-state-border);
      background: var(--empty-state-backgorund);
    }

    p{
      margin: 0;
    }

    .add-banner{
      margin-bottom: 8px;
      color: #141416;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 21.6px;

      [data-theme="dark"] & {
        color: #fff;
      }
    }

    .add-banner-text{
      color:  #878787;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;

      [data-theme="dark"] & {
        color: #9E9E9E;
      }
    }

    .add-banner-icon{
      margin-bottom: 8px;
    }

    .add-banner-icon ::v-deep .svg-inner path {
      fill: #9E9E9E !important;

      [data-theme="dark"] & {
        fill: #fff !important;
      }
    }
  }
}

</style>